import { useState, useCallback, useMemo } from 'react';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { useMount } from 'react-use';
import { FrGa } from '~/modules/SDK/FrGa/FrGa';
import { arrayUnion, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';
import { FrFirebase } from '~/modules/SDK/firebase/FrFirebase';
/** FireBase firestore "symbol_watch_list" */
export const useSymbolWatchList = (groupName) => {
    const { meUserState } = useMeStore();
    const uid = meUserState?.uid || undefined;
    //firestore自選股 集合:symbol_watch_list */
    // const collectionRef = firestore().collection('symbol_watch_list')
    const [symbolList, setSymbolList] = useState();
    /** 群組 */
    const symbolListArray = symbolList?.[groupName];
    /** 獲取自選股清單 */
    const getSymbolList = useCallback(() => {
        if (uid) {
            onSnapshot(FrFirebase.getDocRef('symbol_watch_list', uid), docSnap => setSymbolList(docSnap.data()));
            // collectionRef.doc(userCode).onSnapshot(doc => setSymbolList(doc.data() as UserList))
        }
    }, [uid]);
    /** 第一次使用時創建xxxx_group空文件 */
    const creatValueFile = useCallback(() => {
        if (symbolListArray === undefined && uid !== undefined) {
            setDoc(FrFirebase.getDocRef('symbol_watch_list', uid), {
                [groupName]: arrayUnion(),
            }, { merge: true }).then(() => {
                FrGa.event({ 自選股__創建空文件: { groupName } });
            });
            // collectionRef
            //   .doc(uid)
            //   .set(
            //     {
            //       [groupName]: firestore.FieldValue.arrayUnion(),
            //     },
            //     { merge: true },
            //   )
            //   .then(() => {
            //     FrGa.event({ 自選股__創建空文件: { groupName } })
            //   })
        }
    }, [symbolListArray, uid, groupName]);
    /** 新增自選股 並設定陣列自選股檔數 */
    const addSymbol = useCallback((symbol, listLength) => {
        if (symbolListArray === undefined ||
            ((symbolListArray?.length ?? 0) < listLength && !symbolListArray?.includes(symbol))) {
            const keep = [...(symbolListArray ?? []), symbol];
            updateDoc(FrFirebase.getDocRef('symbol_watch_list', uid), {
                [groupName]: arrayUnion(symbol),
            }).then(() => {
                FrGa.event({ 自選股__新增自選股: { groupName, target: [symbol] } });
            });
            // collectionRef
            //   .doc(uid)
            //   .update({
            //     [groupName]: firestore.FieldValue.arrayUnion(symbol),
            //   })
            //   .then(() => {
            //     FrGa.event({ 自選股__新增自選股: { groupName, target: [symbol] } })
            //   })
        }
        if (symbolListArray?.includes(symbol))
            alert('自選股清單已有此商品!');
        if ((symbolListArray?.length ?? 0) >= listLength)
            alert('自選股數量已達上限!');
    }, [symbolListArray, uid, groupName]);
    /** 移除選取的自選股 並設定陣列自選股檔數 */
    const removeSymbol = useCallback((symbolNumber) => {
        const symbolListRemove = [...symbolListArray];
        const index = symbolListRemove.indexOf(symbolNumber);
        symbolListRemove.splice(index, 1);
        updateDoc(FrFirebase.getDocRef('symbol_watch_list', uid), {
            [groupName]: symbolListRemove,
        }).then(() => {
            FrGa.event({
                自選股__移除自選股: {
                    groupName,
                    replace: symbolListRemove,
                    target: symbolListArray || [],
                },
            });
        });
        // collectionRef
        //   .doc(uid)
        //   .update(
        //     {
        //       [groupName]: symbolListRemove,
        //     },
        //     // { merge: true },
        //   )
        //   .then(() => {
        //     FrGa.event({
        //       自選股__移除自選股: {
        //         groupName,
        //         replace: symbolListRemove,
        //         target: symbolListArray || [],
        //       },
        //     })
        //   })
    }, [groupName, symbolListArray, uid]);
    /** 除全部自選股 並設定陣列自選股檔數 */
    const removeAllSymbol = useCallback(() => {
        if (symbolListArray !== undefined) {
            setDoc(FrFirebase.getDocRef('symbol_watch_list', uid), {
                [groupName]: [],
            }, { merge: true }).then(() => {
                FrGa.event({ 自選股__移除所有自選股: { groupName } });
            });
            // collectionRef
            //   .doc(uid)
            //   .set(
            //     {
            //       [groupName]: [],
            //     },
            //     { merge: true },
            //   )
            //   .then(() => {
            //     FrGa.event({ 自選股__移除所有自選股: { groupName } })
            //   })
        }
    }, [groupName, symbolListArray, uid]);
    useMount(() => {
        getSymbolList();
        creatValueFile();
    });
    return useMemo(() => {
        return {
            state: {
                symbolListArray: symbolListArray || [],
            },
            acts: { addSymbol, removeSymbol, removeAllSymbol, getSymbolList },
        };
    }, [addSymbol, removeSymbol, removeAllSymbol, symbolListArray, getSymbolList]);
};
